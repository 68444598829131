<template>
  <select class="custom-select" v-model="valueChild">
    <option :value="index" v-for="(l, index) in list" :key="index">{{l}} </option>
  </select>
</template>

<script>
export default {
  props: {
    value: {}
  },
  computed: {
    valueChild: {
      get() { return this.value; },
      set(val) { this.$emit('input', val); }
    },
    list () {
      return this.$store.state.config.logistic ? this.$store.state.config.logistic.inputs.types : [];
    }
  }
}
</script>

<style>

</style>
