<template>
  <div>
    <div class="form-group">
      <!-- <label for="">Volumen</label> -->
      <div class="btn-group">
        <!-- <button 
          class="btn btn-secondary" 
          @click="volume = 1" 
          :class="{'active': volume == 1}">Unidad</button>
        <button 
          class="btn btn-secondary" 
          @click="volume = 2" 
          :class="{'active': volume == 2}">Paquete/Caja</button> -->

        <ProductVolume v-model="volume"></ProductVolume>
      </div>
    </div>
    <div class="form-group">
      <label for="">Cantidad</label>
      <!-- <app-paginate ref="page" @change="quantity = $event"></app-paginate> -->
      <app-input-integer v-model="quantity"></app-input-integer>
    </div>
    <div class="form-group">
      <label for="">Precio</label>
      <app-input-money :quantity.sync="price" :selCurrDis="true"
        :moneyCode="money_code"
      ></app-input-money>
    </div>
    <div class="form-group">
      <label for="">Codigo Producto</label>
      <SelectorByCode @product="save($event)" ref="sel"></SelectorByCode>
    </div>
  </div>
</template>

<script>
import SelectorByCode from "../../store-module/products/SelectorByCode";
import { LogisticService } from '../service';
import ProductVolume from "../../store-module/products/Volume"

export default {
  components: {
    SelectorByCode,
    ProductVolume
  },
  props: {
    input_id: {},
    money_code: {}
  },
  data: () => ({
    price: undefined,
    volume: 1,
    quantity: 1
  }),
  mounted() {
    // this.$refs.page.setPageOptions({
    //   current_page: 1,
    //   total: 100,
    //   per_page: 1
    // });
  },
  methods: {
    reset() {
      this.price = undefined;
      this.$refs.sel.reset();
      this.$refs.sel.focus();
      // this.$refs.page.setPageOptions({
      //   current_page: 1,
      //   total: 100,
      //   per_page: 1
      // });
    },
    save(product) {
      this.$emit('preInput');
      let price = 0;
      if(this.price) {
        price = this.price;
      }else {
        price = product.unit_price;
      }
      
      LogisticService.saveInputDetail({
        input_id: this.input_id,
        product_id: product.id,
        quantity: this.volume == this.quantity ? this.quantity : product.units_by_pack*this.quantity,
        unit_price: this.volume == this.quantity ? this.quantity*price : this.quantity*price/product.units_by_pack
      }).then(() => { this.$emit('submitted'); });
    }
  }
}
</script>

<style>

</style>
