<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>TIPO</label>
      <SelectType
        v-model="reg.type_id"
        :disabled="disabled || selTypDis"
      ></SelectType>
      <app-small-form-errors :errors="errors.type_id"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for>ALMACEN</label>
      <SelectStore
        ref="selSto"
        v-model="reg.store_id"
        :disabled="disabled"
      ></SelectStore>
      <app-small-form-errors :errors="errors.store_id"></app-small-form-errors>
    </div>
    <!-- <div class="form-group">
      <label for>MONEDA</label>
      <app-select-currency :verbose="true" v-model="reg.money_code" :disabled="disabled"></app-select-currency>
      <app-small-form-errors :errors="errors.money_code"></app-small-form-errors>
    </div> -->
    <div class="form-group">
      <label for>PROVEEDOR</label>
      <SelectSupplier
        ref="selSup"
        v-model="reg.supplier_id"
        :disabled="disabled"
      ></SelectSupplier>
    </div>
    <div class="form-group">
      <label for>TIPO DE COMPROBANTE</label>
      <SelectProofPayment
        v-model="reg.ticket_type"
        :disabled="disabled"
      ></SelectProofPayment>
    </div>
    <div class="form-group">
      <label for>NUMERO DE TICKET</label>
      <input
        type="text"
        v-model="reg.ticket_number"
        class="form-control"
        :disabled="disabled"
      />
    </div>
    <div class="form-group">
      <label for>GUIA</label>
      <input
        type="text"
        v-model="reg.adds.guide"
        class="form-control"
        :disabled="disabled"
      />
    </div>
    <div class="form-group">
      <label for>FECHA DE EMISION</label>
      <app-datepicker v-model="reg.date_issue"></app-datepicker>
    </div>
    <app-button-submit
      v-show="!disabled"
      :disabled="errors"
    ></app-button-submit>

    <div class="form-group">
      <router-link
        v-show="reg.output_id"
        :to="'/logistic/outputs/' + reg.output_id"
        >transferencia de otra area</router-link
      >
    </div>
  </form>
</template>

<script>
import validate from "validate.js";
import SelectType from "./SelectType";
import SelectStore from "../stores/Select";
import SelectSupplier from "../suppliers/Select";
import SelectProofPayment from "../SelectProofPayment";
import { LogisticService } from "../service";

const formRules = {
  type_id: { presence: { message: "Requerido" } },
  store_id: { presence: { message: "Requerido" } },
  money_code: { presence: { message: "Requerido" } }
};

export default {
  components: {
    SelectType,
    SelectStore,
    SelectSupplier,
    SelectProofPayment
  },
  props: {
    disabled: {
      default: false
    },
    selTypDis: {
      default: true
    }
  },
  data: () => ({
    reg: {
      adds: {}
    }
  }),
  computed: {
    errors() {
      let err = validate(this.reg, formRules, { fullMessages: false });
      return err ? err : false;
    }
  },
  methods: {
    save() {
      if (!this.errors) {
        LogisticService.saveInput(this.reg).then(res =>
          this.$router.push({
            path: "/logistic/inputs/" + res
          })
        );
      }
    },
    loadReg(reg) {
      this.reg = reg;
      this.$refs.selSto.setValueFromId(reg.store_id);
      this.$refs.selSup.setValueFromId(reg.supplier_id);
    },
    reset() {
      this.reg = { adds: {} };
      this.reg.type_id = 1; // entrada directa;
      this.reg.money_code = this.$store.state.config.money.default;
    }
  }
};
</script>

<style>
</style>
