<template>
  <div>
    <h4>NOTA DE ENTRADA {{ input_id }}</h4>
    <div class="row">
      <div class="col-md-4">
        <div class="card mb-3">
          <div class="card-body">
            INGRESO POR CODIGO
            <FastCode
              ref="fastCode"
              @submitted="
                $refs.tr.gl();
                $refs.fastCode.reset();
              "
              @preInput="$refs.trLoading.state(true)"
              :input_id="input_id"
              :money_code="reg.money_code"
            ></FastCode>
          </div>
        </div>

        <FormInput ref="formInput" :disabled="true"></FormInput>
      </div>
      <div class="col">
        <app-table-registers
          ref="tr"
          :getList="getList"
          @btnNew="
            $refs.formDet.reset();
            $refs.dFormDet.show();
          "
        >
          <template slot="table">
            <thead>
              <tr>
                <th>PRODUCTO</th>
                <th>CANTIDAD</th>
                <th>PRECIO UNITARIO</th>
                <th>SUB TOTAL</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <app-tr-loading ref="trLoading" :colspan="5"></app-tr-loading>
              <tr v-for="l in list.data" :key="l.id">
                <td>{{ l.product_name }}</td>
                <td>{{ l.quantity }}</td>
                <td>
                  <app-span-money
                    :quantity="l.unit_price"
                    :moneyCode="l.money_code"
                  ></app-span-money>
                </td>
                <td>
                  <app-span-money
                    :quantity="l.unit_price * l.quantity"
                    :moneyCode="l.money_code"
                  ></app-span-money>
                </td>
                <td>
                  <div class="btn-group">
                    <button
                      class="btn btn-light btn-sm"
                      @click="
                        $refs.dDelDet.show();
                        $refs.dDelDet.val = l;
                      "
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </app-table-registers>
      </div>
    </div>

    <app-modal-basic ref="dFormDet">
      <FormDetails
        ref="formDet"
        :input_id="input_id"
        @submitted="
          $refs.dFormDet.hide();
          $refs.tr.gl();
        "
        :money_code="reg.money_code"
      ></FormDetails>
    </app-modal-basic>

    <app-modal-yn
      ref="dDelDet"
      @yes="
        $refs.dDelDet.hide();
        deleteDet($refs.dDelDet.val.id);
      "
      @no="$refs.dDelDet.hide()"
    >
      <p>
        Eliminar detalle:
        <span v-if="$refs.dDelDet && $refs.dDelDet.val">
          <b>{{ $refs.dDelDet.val.quantity }}</b> unidades de
          <b>{{ $refs.dDelDet.val.product_name }}</b>
        </span>
      </p>
    </app-modal-yn>
  </div>
</template>

<script>
import { LogisticService } from "../service";
import FormDetails from "./FormDetails.vue";
import FormInput from "./FormCreate";
import FastCode from "./FastCode";

export default {
  components: {
    FastCode,
    // SelectStore,
    FormDetails,
    FormInput
  },
  props: {
    input_id: {}
  },
  data: () => ({
    list: {},
    reg: {}
  }),
  mounted() {
    LogisticService.getInput(this.input_id).then(res => {
      this.reg = res;
      this.$refs.formInput.loadReg(this.reg);
      // this.$refs.selSto.setValueFromId(this.reg.store_id);
    });
  },
  methods: {
    deleteDet(id) {
      LogisticService.deleteInput(id).then(() => this.$refs.tr.gl());
    },
    getList({ page, search }) {
      return new Promise(rsv => {
        LogisticService.getInputDetails({
          page,
          search,
          input_id: this.input_id
        }).then(res => {
          this.list = res;
          this.$refs.trLoading.state(false);
          rsv(res);
        });
      });
    }
  }
};
</script>

<style>
</style>
